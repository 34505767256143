.banner-container {
    position: relative;
    text-align: center;
  }
  
  .banner-heading {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: clamp(1rem, 5vw, 4rem); /* Scales with screen size */
  font-weight: bold;
  text-shadow: 0 0 10px red, 0 0 20px red, 0 0 30px red;
  text-align: center;
  width: 90%;
  }
  
  .Join-family-banner {
    width: 100%;
  height: auto;
  object-fit: cover;
  }

   .form-label-red{
    
    color: #C61D21;
   }
   input[type="date"] {
    color: black !important;
  }
  .addRelativebtn,.submitbtn {
    padding: 10px 30px !important;
    font-size: 90% !important;
    min-width: auto !important;
  }
  .removeRelativebtn{
    padding: 2px 12px !important;
    font-size: 90% !important;
    min-width: auto !important;
  }
  