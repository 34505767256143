.mob {
  .header {
    background-color: #c61d21;
    padding: 0 120px;
    align-items: center;
  }

  .iskcon-logo {
    width: 5rem;
  }

  .nav-bar {
    display: flex;
    background-color: white;
    padding: 0 130px;
    justify-content: space-between;
    

  }

  .nav-link {
    color: #5c5c5c !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    width: 100%;
    text-align: left;


  }

  .nav-link .active {
    color: #c61d21 !important;
    font-weight: 600 !important;
  }

  .nav-Link {
    text-decoration: none !important;


  }

  .dropdown-menu .dropdown-item:hover,
  .dropdown-menu .dropdown-item:focus,
  .dropdown-menu .dropdown-item:active {
    background-color: transparent;
    color: #5c5c5c;

  }

  .dropdown-item{
    padding: 0px 16px ;
  }

  .dropdown-item:hover {
    color: #c61d21;
  }

  .dropdown-menu {
   
    cursor: pointer;
  }

  .dropdown-submenu {
    position: relative;
  }

 

  /* --- */

  .disblock {
    display: block;
    padding: 0px 0px 0px 1.3rem;

  }

  .disnone {
    display: none;
  }

  .disblock .dropdown-item {
    font-size: 15px;

  }

  
 


  /* --- */



  @media (max-width: 768px) {
    .nav-bar {
      padding: 0 20px;
    }
  }

  @media (max-width: 600px) {
    .header {
      padding: 0 20px;
    }

    .nav-bar {
      display: block !important;
      padding: 20px;
    }

    .nav-bar .nav-link {
      padding: 10px 0;
    }

    .collapse {
      visibility: visible !important;
    }
  }
}

/*----------------------*/

.desktop {
  .header {
    background-color: #c61d21;
    padding: 0 120px;
    align-items: center;
  }

  .iskcon-logo {
    width: 5rem;
  }

  .nav-bar {
    display: flex;
    background-color: white;
    padding: 0 130px;
    justify-content: space-between;
    /* background-color: #c61d21; */

  }

  .nav-link {
    color: #5c5c5c !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    /* color: white; */
    position: relative;
  }

  .nav-link .active {
    color: #c61d21 !important;
    font-weight: 600 !important;
  }

  .nav-Link {
    text-decoration: none !important;
  }

  /* Updated dropdown styles */
  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    padding: 0.5rem 0;
    margin: 0;
    cursor: pointer;
  }

  /* Show dropdown on hover */
  #programDropdown:hover+.dropdown-menu,
  .dropdown-menu:hover {
    display: block;
    animation: slideIn 0.3s ease-in-out;
  }

  .dropdown-menu .dropdown-item {
    padding: 0.5rem 1.5rem;
    color: #5c5c5c;
    transition: color 0.2s ease;
  }

  .dropdown-menu .dropdown-item:hover,
  .dropdown-menu .dropdown-item:focus,
  .dropdown-menu .dropdown-item:active {
    background-color: transparent;
    color: #c61d21;
  }

  /* Submenu styles */
  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -5px;
    display: none;
    
  }

  /* Show submenu on hover */
  .dropdown-submenu:hover>.dropdown-menu {
    display: block;
    animation: slideIn 0.3s ease-in-out;
    

  }

  /* Keep submenu visible while hovering */
  .dropdown-menu li:hover>.dropdown-menu {
    display: block;
  }

  /* Animation */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-10px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  /* Additional hover effects */
  .nav-link:hover .dropdown-menu,
  .dropdown-menu:hover {
    display: block;
  }

  .dropdown-item:hover {
    color: #c61d21;
  }

  /* Responsive styles */
  @media (max-width: 768px) {
    .nav-bar {
      padding: 0 20px;
    }

    /* Reset dropdown positions for mobile */
    .dropdown-submenu>.dropdown-menu {
      left: 0;
      margin-top: 0;
      padding-left: 1rem;
    }

    /* Adjust animation for mobile */
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateY(-10px);
      }

      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @media (max-width: 600px) {
    .header {
      padding: 0 20px;
    }

    .nav-bar {
      display: block !important;
      padding: 20px;
    }

    .nav-bar .nav-link {
      padding: 10px 0;
    }

    .collapse {
      visibility: visible !important;
    }

    /* Mobile dropdown adjustments */
    .dropdown-menu {
      position: static;
      float: none;
      width: 100%;
      margin-top: 0;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }

    .dropdown-submenu>.dropdown-menu {
      display: block;
      margin-left: 15px;
    }
  }
}

/*---------------*/

.header {
  background-color: #c61d21;
  padding: 0 120px;
  align-items: center;
}

@media (max-width: 600px) {
  .header {
    padding: 0 20px;
  }

  .donateBtn {
    background-color: #f3b01b !important;
    padding: 0.2rem 0.8rem !important;
    font-size: 14px !important;
    line-height: 0 !important;
    height: 30px !important;
  }

  /* .navbar-toggler {
    display: block;
  } */
}

/* .navbar-toggler {
  display: none;
} */
.iskcon-logo {
  width: 5rem;
}

.donateBtn {
  background-color: #f3b01b !important;
  padding: 0.2rem 2rem !important;
  font-size: 18px !important;
  line-height: 0 !important;
  height: 45px !important;
  border: 3px solid white !important;
  transition: transform 0.3s ease-in-out !important;
}

.donateBtn:hover {
  background-color: white !important;
  color: #f3b01b !important;
  border: 3px solid #f3b01b !important;
  transform: scale(1.1);
  animation: none !important;
}