@import url("https://fonts.googleapis.com/css2?family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap");

.features-section {
  position: relative;
  background-color: #c61d21;
  color: white;
  padding: 20px;
  min-height: 30vh;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

.features-container {
  max-width: 95%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.features-content {
  width: 100%;
}

.features-content h1 {
  text-align: center;
  font-family: "Source Serif 4", serif;
  font-weight: 600;
  color: #f3b01b;
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 1.2;
}

.features-content ul {
  list-style-type: disc;
  margin: 20px auto;
  padding: 0 20px;
  line-height: 1.5;
  font-size: 16px;
  color: #ffdebd;
  text-align: justify;
}

.features-content ul li {
  margin: 15px 0;
}

/* Background Images */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.top-left-image {
  position: absolute;
  top: -25px;
  left: -25px;
  width: 140px;
  height: auto;
  opacity: 1;
}

.bottom-right-image {
  position: absolute;
  bottom: -28px;
  right: -20px;
  width: 233px;
  height: auto;
  opacity: 1;
}

@media (max-width: 767px) {
  .features-content h1 {
    font-family: "Open Sans-SemiBold", Helvetica;
    font-weight: 400;
  }
}

@media (min-width: 768px) {
  .features-section {
    padding: 40px;
  }

  .features-content h1 {
    font-size: 54px;
  }

  .features-content ul {
    font-size: 26px;
    line-height: 1.8;
    padding: 0 40px;
  }

  .top-left-image {
    top: -50px;
    left: -50px;
    width: 280px;
  }

  .bottom-right-image {
    bottom: -56px;
    right: -39px;
    width: 466px;
  }
}

@media (min-width: 1024px) {
  .features-section {
    padding: 32px 60px;
  }

  .features-content ul {
    max-width: 100%;
    margin: 30px auto;
  }
}

@media (min-width: 1440px) {
  .features-container {
    max-width: 95%;
  }

  .features-content h1 {
    font-size: 70px;
  }

  .features-content ul {
    font-size: 32px;
    line-height: 2;
    max-width: 1600px;
  }

  .features-content ul li {
    margin: 20px 0;
  }

  .top-left-image {
    top: -75px;
    left: -75px;
    width: 420px;
  }

  .bottom-right-image {
    bottom: -84px;
    right: -58px;
    width: 699px;
  }
}
