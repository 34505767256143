.quickdonatebanner {
  background-image: url(./assests/banner.webp);
  background-position: center;
  background-size: cover;
  height: 75vh;
}

@media (max-width: 768px) {
  .quickdonatebanner {
    background-image: url(./assests/bannermobile.webp);
    height: 35vh;
  }
}
