.nitya-sevak-heading {
  background: radial-gradient(#c61d21 0%, #600e10 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 5.5rem;
  text-align: center;
}

.nitya-sevak .card {
  background: radial-gradient(#c61d21 0%, #600e10 100%);
  border-radius: 15px;
  border: 4px solid #c61d21;
  margin: 40px;
  padding: 10px 0;
  width: 30%;
}

.nitya-sevak .card-head {
  height: 5rem;
  padding: 10px 30px;
  border-bottom: 3px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nitya-sevak .card-head h2 {
  color: white;
  text-align: center;
  font-size: 1.4rem;
}

.nitya-sevak .card-content {
  padding: 20px 30px 10px 30px;
  text-align: center;
}

.nitya-sevak .card-content a {
  color: white;
  font-size: 1.3rem;
}

.nitya-sevak .card-content h5 {
  color: white;
  font-size: 1.3rem;
}

.card-section {
  display: flex;
}

@media (max-width: 678px) {
  .nitya-sevak-heading {
    font-size: 2.5rem;
  }

  .card-section {
    display: block;
  }

  .nitya-sevak .card {
    width: 80%;
  }
}
