.page-1 {
  display: flex;
  flex-direction: column;
  background-image: url("../img/image\ \(1\).webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.section-elementor {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgb(195, 76, 65) 28.3%,
    rgb(191.3, 63.85, 48.09) 50.75%,
    rgb(188, 53, 33) 70.8%,
    rgba(255, 255, 255, 0.05) 100%
  );
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0px 0px 60px 60px;
  height: 41px;
  width: 1728x;
}

.section-elementor .div-elementor {
  height: 81px;
  left: 353px;
  position: relative;
  top: 10px;
  width: 1071px;
}

.shadow-img {
  padding: 3rem 0 7.2px;
}

.top1-left-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 115px;
  height: auto;
  opacity: 1;
}

.top1-right-image {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 115px;
  height: auto;
  opacity: 1;
}

@media (min-width: 768px) {
  .top1-left-image {
    top: 0px;
    left: 0px;
    width: 230px;
  }

  .top1-right-image {
    top: 0px;
    right: 0px;
    width: 230px;
  }
}

@media (max-width: 767px) {
  .shadow-img {
    padding-top: 0;
  }
}
