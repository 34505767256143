.vaikunta-container {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  background-color: #f9f5f0;
}

/* Header Section */

.header-text {
  position: absolute;
  top: 6%;
  left: 28%;
}

.header-image {
  max-width: 100%;
}

/* Introduction Section */
.vaikunta-intro {
  width: 100%;
  padding: 2%;
}
.vaikunta-intro p {
  color: #000;
  font-family: "Open Sans";
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
}

/* Sevas Section */
.sevas-section {
  width: 100%;
  margin: 0 auto;
}

.sevas-section h2 {
  font-family: "Source Serif Pro";
  text-align: center;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--lin, linear-gradient(180deg, #c61d21 0%, #600e10 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.seva-card {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.seva-card h3 {
  color: var(--900, var(--Color-3, #c30000));
  font-family: "Open Sans";
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135.882%;
  margin-bottom: 10px;
}

.seva-card .seva-description {
  color: #000;
  font-family: "Open Sans";
  text-align: start;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135.882%;
  margin-bottom: 15px;
}

/* Donation Buttons */
.vaikunta-donation-options {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 3rem 6rem;
  gap: 80px;
  border-radius: 1.28156rem;
  border: 3.342px solid #bc3521;
  background: #fff0de;
  box-shadow: 0px 4.101px 15.6px 0px rgba(119, 15, 50, 0.25);
}

.donation-button {
  background-color: #bc3521;
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 2.42081rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px;
  border: none;
  border-radius: 1.00644rem;
  cursor: pointer;
  /* flex: 1 1 calc(50% - 10px); Adjust to fit buttons in two columns */
}

.donation-button:hover {
  background-color: #a52a2a;
}

.donate-text {
  color: #c61d21;
  margin: 1rem 0 0 0;
  text-align: center;
  font-family: "Open Sans";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

/* Responsive Design */
@media (max-width: 768px) {
  .donation-button {
    font-size: 0.9rem;
    padding: 8px;
  }

  .vaikunta-donation-options {
    padding: 1rem;
    gap: 20px;
  }

  .sevas-section ul {
    font-size: 1rem;
  }

  .vaikunta-intro p {
    font-size: 1rem;
  }

  .sevas-section h2 {
    font-size: 1.8rem;
  }

  .seva-card h3 {
    font-size: 1.2rem;
  }

  .seva-card .seva-description {
    font-size: 0.9rem;
    text-align: justify;
  }
}
