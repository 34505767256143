.mainPage {
  background: url("./assets/images/pageBg.webp");
  background-repeat: no-repeat !important;
  background-size: cover;
}

.mainSection {
  padding: 50px 0;
}

.mainSection h1 {
  text-align: center;
  color: #c61d21;
  font-size: 100px;
  text-transform: uppercase;
  font-family: "DM Serif Display", serif;
}

.mainSection h4 {
  text-align: center;
  font-size: 1.8rem;
  font-family: sans-serif;
  width: 70%;
}

.btn {
  background-color: #c61d21 !important;
  color: white !important;
  font-size: 25px !important;
  padding: 1rem 2rem !important;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.btn:hover {
  background-color: #df1216;
  box-shadow: 0 2px 4px #c61d21;
  animation: bounce 0.3s;
}

.mustWatch {
  padding: 0 80px;
}

.mustWatch h1 {
  text-align: center;
  color: #c61d21;
  font-size: 80px;
  text-transform: uppercase;
  font-family: "DM Serif Display", serif;
}

.newsSection {
  padding: 120px 80px;
  background: url("./assets/images/newsBg.webp");
  background-repeat: no-repeat !important;
  background-size: cover;
}

.newsSection h1 {
  text-align: center;
  color: #c61d21;
  font-size: 80px;
  font-family: "DM Serif Display", serif;
}

.discover-more {
  padding: 50px 0;
  text-align: center;
}

.discover-more h1 {
  color: #c61d21;
  font-size: 80px;
  font-family: "DM Serif Display", serif;
}

.discover-items {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.item {
  text-align: center;
  font-family: "DM Serif Display", serif;
}

.circle-image {
  position: relative;
  width: 60%;
  height: 60%;
  cursor: pointer;
}

.circle {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.6s ease;
  border-radius: 50%;
}

.circle-image:hover .circle {
  transform: rotate(360deg);
}

.inner-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 85%;
  height: 80%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: 2;
  border-radius: 50%;
  box-sizing: border-box;
  padding-top: 0px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.item-title {
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  color: #000000 !important;
  margin-top: 15px !important;
  font-family: "Open Sans", sans-serif !important;
}

.banner-container {
  position: relative;
}

.banner {
  width: 100%;
  height: auto;
  /* max-width: 1728px; */
  justify-content: center;
}

.register-button {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f3b01b;
  width: 377px;
  height: 52px;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  border: 5px solid white;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 52px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.item1 {
  text-align: center;
  font-family: "DM Serif Display", serif;
  display: none;
}

@media (max-width: 600px) {
  .discover-more {
    padding: 0;
  }

  .discover-more h1 {
    font-size: 2.5rem;

    margin: 40px 0;
  }

  .discover-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 10px;
  }

  .item:last-child {
    display: none !important;
  }

  .item1 {
    display: block;
  }

  .mainSection {
    padding: 0 10px;
  }

  .mainSection h1 {
    margin-top: 13px;
    font-size: 2.5rem;
  }

  .mainSection h4 {
    font-size: 1.2rem;
    text-align: justify;
    width: 100%;
  }

  .btn {
    font-size: 15px !important;
    padding: 0.5rem 1rem !important;
  }

  .mustWatch {
    padding: 0 20px;
  }

  .mustWatch h1 {
    margin-top: 40px;
    font-size: 2.5rem;
  }

  .newsSection {
    padding: 50px 20px;
    background: url("./assets/images/newsBg.webp");
    background-repeat: no-repeat !important;
    background-size: cover;
    background-position: center;
  }

  .newsSection h1 {
    padding-top: 20px;
    font-size: 2.2rem;
  }

  .register-button {
    bottom: 15px;
    width: 30%;
    height: 1.5rem;
    font-size: 10px;
    border: 2px solid white;
    border-radius: 10px;
    line-height: 0px;
  }
}

.lordkrishna {
  text-align: start;
}
.joinkrishna {
  text-align: start;
}

@media (max-width: 768px) {
  .lordkrishna {
    text-align: center;
  }
  .joinkrishna {
    text-align: center;
  }
}
