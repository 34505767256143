.gradient-mask {
  mask-image: linear-gradient(to top, transparent 0%, black 25%);
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 25%);
}

@media (min-width: 768px) {
  .gradient-mask {
    mask-image: linear-gradient(to right, black 75%, transparent 100%);
    -webkit-mask-image: linear-gradient(to right, black 75%, transparent 100%);
  }
}
