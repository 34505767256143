@import url("https://fonts.googleapis.com/css2?family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap");

.page3 {
  width: 100%;
  background-image: url("../img/image\ \(3\).webp"); /* Replace with actual path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.benefits-section {
  background-color: #ffe5cc;
  padding: 10px;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .benefits-section {
    background-color: transparent;
  }
}

.benefits-title {
  font-family: "Source Serif 4", serif;
  color: #c61d21;
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: left;
}

@media (max-width: 767px) {
  .benefits-title {
    text-align: center;
    font-family: "Open Sans-SemiBold", Helvetica;
  }
}

.benefits-card {
  background-color: #fff0de;
  border: 2px solid #c61d21;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.semi-bold {
  font-family: "Open Sans-Bold", Helvetica;
}

.benefits-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
  text-align: justify;
}

.benefits-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 20px;
}

.benefits-list li::before {
  content: "•";
  color: black;
  position: absolute;
  font-weight: 900;
  left: 0;
}

.benefits-list li strong {
  font-weight: 700;
}

.benefits-description {
  margin-bottom: 20px;
  line-height: 1.5;
  font-weight: bold;
  text-align: justify;
}

.benefits-cta {
  color: #c61d21;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .benefits-section {
    padding: 40px;
  }

  .benefits-title {
    font-size: 2.5rem;
  }

  .benefits-card {
    padding: 30px;
    background-color: #feead2;
  }

  .benefits-list li {
    font-size: 35px;
  }

  .benefits-description {
    font-size: 36px;
  }

  .benefits-cta {
    font-size: 36px;
  }
}

@media (min-width: 1024px) {
  .benefits-section {
    padding: 32px 60px;
  }

  .benefits-title {
    font-size: 70px;
    margin: 0 0 20px 0;
  }

  .benefits-card {
    padding: 40px;
  }
}
