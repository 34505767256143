.slider-container {
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.slider-image-wrapper-1 {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex !important;
}

.slider-image-1 {
  width: 100% !important;
  object-fit: cover;
  border-radius: 25px;
  background-color: transparent;
  border: 3px solid white;
}

.full-screen-svg {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: 0;
}

@media (max-width: 768px) {
  .slider-image-1 {
    width: 95%;
  }
}
