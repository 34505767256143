.left-bg1 {
  position: absolute;
  left: 0;
  top: 10;
  width: 20%;
  z-index: 1;
  border-top-left-radius: 25px;
}

.right-bg1 {
  position: absolute;
  right: 0;
  top: 10;
  width: 20%;
  z-index: 1;
  border-top-right-radius: 25px;
}

.bannerNor {
  box-shadow: 0px 0px 20px 5px #c61d21 !important;
}

@media (max-width: 600px) {
  .right-bg1 {
    border-radius: 0;
  }

  .left-bg1 {
    border-radius: 0;
  }

  .banner {
    border-radius: 0 !important;
    box-shadow: none !important;
  }
}
