.nitya-contribution {
  padding: 80px 0;
}

.nitya-contribution h1 {
  color: #c61d21;
  font-weight: 700;
}

.nitya-contribution h3 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4;
}

.nitya-contribution .head {
  width: 70%;
}

.nitya-contribution-img1 {
  width: 20%;
  height: 20%;
}

.nitya-contribution-img2 {
  width: 19%;
  height: 19%;
}
.nitya-contribution-img3 {
  width: 20%;
  height: 20%;
}
.nitya-contribution-img4 {
  width: 20%;
  height: 20%;
}
.nitya-contribution-img5 {
  width: 18%;
  height: 18%;
}

.img-section {
  display: flex;
  justify-content: center;
}

@media (max-width: 678px) {
  .nitya-contribution .head {
    width: 95%;
  }
  .nitya-contribution {
    padding: 0 0;
  }
  .nitya-contribution h3 {
    font-size: 1rem;
    line-height: 1.2;
  }

  .img-section {
    display: ruby;
  }

  .nitya-contribution-img1 {
    width: 50%;
    height: 50%;
  }

  .nitya-contribution-img2 {
    width: 50%;
    height: 50%;
    padding: 0 0 15px 15px;
  }

  .nitya-contribution-img3 {
    width: 50%;
    height: 50%;
  }

  .nitya-contribution-img4 {
    width: 50%;
    height: 50%;
  }

  .nitya-contribution-img5 {
    width: 45%;
    height: 45%;
  }
}
