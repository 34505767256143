.fontTwo {
  font-family: "Neuton", serif;
}

.text-shadow {
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

.bannerData {
  background: rgb(198, 29, 33);
  background: linear-gradient(
    0deg,
    rgba(198, 29, 33, 0.6) 0%,
    rgba(198, 29, 33, 1) 7%,
    rgba(198, 29, 33, 1) 94%,
    rgba(198, 29, 33, 0.6) 100%
  );
}

@media (min-width: 1280px) {
  .bannerData {
    background: transparent;
  }
}

.subBanner .box {
  @apply col-span-1 xl:flex-1 flex flex-col items-center bg-[linear-gradient(to_bottom,_#EEDACF,_#FB9260)] mt-0 py-4 xl:py-4 gap-3 xl:first:rounded-bl-[40px] xl:last:rounded-br-[40px];
}

.subBanner .box img {
  @apply h-[50px] xl:h-[70px];
}

.subBanner .box p {
  @apply text-white text-xs xl:text-xl;
}

.innerForm {
  @apply mt-8;
}

.sevaContainer {
  @apply flex flex-col gap-4;
}

.sevaContainer label {
  @apply bg-[#F9E7D0] p-3 rounded-xl flex items-center gap-3 font-bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.sevaContainer input {
  @apply rounded-xl text-[#909192];
}

.sevaContainer input:checked {
  @apply accent-blue-700;
}

.sevaContainer .customAmount input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.sevaContainer .customAmount input[type="number"] {
  -moz-appearance: textfield;
}

.innerForm input {
  @apply rounded-xl;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.tandd label {
  @apply text-white text-[12px] flex items-center gap-2;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.dates .innerDate {
  @apply flex items-center gap-3 col-span-1 bg-[#EF6063] px-2 py-2 rounded-2xl;
}

.dates .innerDate .month {
  @apply bg-white px-3 py-2 xl:px-4 xl:py-3 rounded-xl text-lg;
}

.dates .innerDate .dateInfo p {
  @apply text-white text-[10px] xl:text-sm m-0;
}

.infoBox .innerInfoBox h3 {
  @apply text-2xl font-bold;
}

.infoBox .innerInfoBox p {
  @apply text-base text-left text-black;
}

.readMoreContent .infoBox .innerInfoBox h3 {
  @apply text-black;
}

.readMoreContent .infoBox .innerInfoBox p {
  @apply font-normal pt-2;
}

.mahaKumbhMela {
  .form-header {
    font-size: 28px;
    /* color: rgba(188, 53, 33, 1); */
    color: #ff7300;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
    padding: 10px 0;
    border-bottom: 2px solid;
  }

  .donation-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
    text-align: center;
  }

  .option {
    background-color: rgba(188, 53, 33, 1);

    color: white;
    padding: 15px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.4;
    width: 330px;
    height: 70px;
  }

  .option:last-child {
    grid-column: span 2;
    width: 100%;
  }

  label {
    display: block;
    color: #333;
    margin-bottom: 3px;
  }

  .required {
    color: rgba(188, 53, 33, 1);
    font-weight: bold;
    margin-left: 3px;
  }

  input[type="text"],
  input[type="email"],
  input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .form-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .form-col {
    display: flex;
    flex-direction: column;
  }

  .checkbox-section {
    margin-bottom: 20px;
    text-align: left;
  }

  .checkbox-label {
    color: #333;
    margin-left: 5px;
  }

  input[type="checkbox"] {
    accent-color: rgba(188, 53, 33, 1);
  }

  button.donate-button {
    background-color: rgba(188, 53, 33, 1);
    color: white;
    padding: 12px;
    border: 10px solid white;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
  }

  button.donate-button:hover {
    background-color: rgba(188, 53, 33, 1);
  }

  p {
    margin-top: 10px;
    color: #777;
    /* font-size: 12px; */
    text-align: center;
  }

  @media (max-width: 768px) {
    .donation-box {
      /* width: 100%; */
      border: 0;
      background-color: transparent;
    }

    .option {
      width: 100%;
      height: 100%;
      padding: 5px;
      border-radius: 4px;
      font-size: 8px;
      font-weight: bold;
      line-height: 1.4;
    }

    .form-header {
      font-size: 1.3rem;
      border: 0;
    }
    .form-group {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
      margin: 5px 0;
    }
    .form-group {
      width: 100%;
    }
    form label {
      width: 100%;
      align-items: start;
      color: #000;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .form-col > label {
      color: #000;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .form-col > input {
      margin: 0;
      padding: 2px 10px;
      box-shadow: 0 0 5px;
    }

    .form-col > input::placeholder {
      color: rgba(92, 92, 92, 0.8);
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 700;
    }
    .checkbox-section {
      margin-bottom: 10px;
    }
    .dona {
      margin: 0;
      padding: 2px 10px;
      box-shadow: 0 0 10px;
    }
    .dona::placeholder {
      color: rgba(92, 92, 92, 0.8);
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 700;
    }
    button.donate-button {
      padding: 8px 6px;
      border: 4px solid white;
      border-radius: 5px;
      font-size: 12px;
      width: 40%;
    }
  }
}
