section {
  background: linear-gradient(180deg, #c61d21 0%, #600e10 100%);
  padding: 20px 0;
}

.row {
  padding: 0 40px;
  align-items: center;
  margin: 0 !important;
}

.imgContainer {
  border: 2px solid white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  height: 21rem;
  overflow: hidden;
}

.footerImg {
  width: 100%;
  height: 80%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.textBox {
  padding: 5px;
  background: radial-gradient(50% 50% at 50% 50%, #c61d21 0%, #600e10 100%);
}

.imgHead {
  font-family: "DM Serif Display", serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  color: white;
  margin-bottom: 0;
}

.imgSub {
  font-family: "DM Serif Display", serif;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  color: white;
}

.textHead {
  font-size: 25px;
  color: white;
  font-weight: 600;
}

.form-control {
  padding: 20px 10px !important;
  margin: 10px 0;
}

.form-btn {
  width: 100%;
  height: 70px;
  color: white !important;
  font-weight: 600 !important;
  background-color: #ff7300 !important;
  /* padding: 10px 0 !important; */
  border-radius: 5px;
  /* margin-top: 33px;
  margin-left: -85px;  */
}

.item-text {
  display: flex;
  background-color: white;
  border-radius: 5px;
  padding: 5px 15px;
  gap: 15px;
  align-items: center;
  margin: 10px 0;
}

.item-text .item-img {
  width: 57px;
  height: 57px;
  object-fit: cover;
}

.item-text.updated-item .updated-img {
  width: 57px;
  height: 57px;
}

.item-text .text {
  color: #c61d21;
  font-size: 20px;
}

.footerRight h3 {
  font-size: 25px;
  margin: 0;
}

.footerLeftHead {
  color: white;
  font-family: "DM Serif Display", serif;
  font-size: 40px;
}

.footer-text {
  color: #f3b01b;
  font-weight: 400;
}

.copyright {
  display: flex;
  gap: 5rem;
}

.footer-logo {
  width: 150px;
}

.mob-contact {
  display: none;
}

.footer-sub-text {
  color: white;
  font-weight: 400;
}

.our-temple {
  padding: 40px;
}

.footer-copyright {
  gap: 50px;
}

.digital-dose {
  font-size: 20px;
}

@media (max-width: 600px) {
  .row {
    padding: 0 20px;
  }

  .imgContainer {
    width: 100%;
    height: 16rem;
  }

  .digital-dose {
    padding: 10px 0;
    font-size: 12px;
  }

  .footer-copyright {
    gap: 0px;
    justify-content: space-around;
  }

  .footer-text {
    font-size: 12px;
  }

  .footer-sub-text {
    font-size: 12px;
  }

  .our-temple {
    padding: 10px;
  }

  .copyright {
    display: block;
    padding-top: 10px;
  }

  .copyright h5 {
    font-size: 0.6rem;
  }

  .footer-logo {
    display: none;
  }

  .imgHead {
    font-size: 1rem;
  }

  .imgSub {
    font-size: 0.8rem;
  }

  .mob-contact {
    display: block;
  }

  .mob-contact h3 {
    font-size: 1rem;
    margin: 0;
  }

  .textHead {
    text-align: center;
    font-size: 2rem;
    padding: 10px 0;
  }
  section{
    padding-bottom: 100px;
  }
}
