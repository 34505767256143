.lampimg {
    /* h-[27px] w-[44.5px] float-left mr-3 */

    height: 27px;
    width: 44.5px;
    float: left;
    margin-top: 15px;
    margin-right: 15px;

}

.ourmission {
    height: max-content;
    width: 1440px;

    border-radius: 20px;


}

.ourmission h1 {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    
}
.ourmission p {
    margin-top:3rem;
    font-size: 2rem;
     
 }

 .firstflo{
    height: 432px;
    width: 381px;

 }



.mainsec{ 
    /* background-color: #f4dec6ae; */
    /* bg-cover bg-no-repeat bg-center */
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   width: 100%;

}

/* Replace with Bootstrap classes */

@media (max-width: 600px) {
    .mainsec{
        /* background-size: contain; */
    }
    .lampimg {
        height: 20px;
        width: 30px;
        margin-top: 1px;
        margin-right: 10px;
    }

    .ourmission {
        height: max-content;
        width: 100%;
        margin: 5px;
        
    }
    /* .ourmission img{
        height: 187px;
        width: 265px;
    } */

    .ourmission p {
       font-size: 1rem;
       /* padding: 10px; */
        
    }

    .firstflo{
        height: 144px;
        width: 99px;
    
     }
}

