.founder-page {
  background: url("./assets/images/pageBg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 4rem;
}

.founder-img {
  padding: 30px 0 50px 0;
}

.founder {
  width: 90%;
}

.img-headline {
  background: radial-gradient(#c61d21 0%, #b41a1e 17%, #600e10 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Source Serif Pro";
  font-weight: 700;
  font-size: 2.6rem;
  word-spacing: 4px;
  letter-spacing: 2px;
}

@media (max-width: 600px) {
  .img-headline {
    font-size: 1.2rem;
    font-family: "Source Sans Pro";
    font-weight: 800;
  }

  .founder-img {
    padding: 20px 0 40px 0;
  }
}
