/* Ramnavami Page Specific Styles */
.ramnavami-container {
  font-family: "Open Sans", sans-serif;
}

.ramnavami-banner-img {
  width: 100vw;
}

.ramnavami-content-bg {
  background-size: cover;
  background-position: center;
  margin-top: -3rem;
  padding-top: 2rem; /* Added padding to prevent overlap */
}

.ramnavami-content {
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding: 0 5%;
  box-sizing: border-box;
}

.ramnavami-headline {
  color: #c61d21;
  font-family: "Source Serif Pro";
  font-weight: 700;
  font-size: 3.4rem;
  word-spacing: 4px;
  letter-spacing: 2px;
  margin: 1.25rem 0;
  padding-top: 1rem; /* Added padding for desktop */
}

.ramnavami-subheadline {
  font-size: 1.8rem;
  font-family: "Source Sans Pro";
  font-weight: 700;
  color: white;
  padding: 5px 40px;
  background-color: #c61d21;
  border: 3px solid white;
  border-top: 0;
  margin: 2.5rem auto; /* Centered the subheadline */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  max-width: 90%; /* Prevent overflow on mobile */
}

.ramnavami-text-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 2%; /* Added side padding */
}

.ramnavami-text-content {
  width: 100%; /* Increased from 95% to make container wider */
  max-width: 1400px; /* Increased from 1100px */
  text-align: justify;
  color: #333;
  line-height: 1.6;
  padding: 0 1rem; /* Increased from 1rem */
}

.ramnavami-text-content p {
  margin-bottom: 1.5rem;
  font-size: 1.3rem; /* Increased from 1.2rem */
}

.ramnavami-text-content strong {
  font-weight: 600;
}

.ramnavami-event-details {
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 8px;
  margin: 1.5rem 0;
}

.ramnavami-event-details p {
  margin-bottom: 0.5rem;
  text-align: left;
  font-size: 1.3rem; /* Added to match increased text size */
}

/* ... (keep all your existing form styles exactly the same) ... */

/* Responsive adjustments - Mobile styles remain exactly the same */
@media (max-width: 1024px) {
  .ramnavami-headline {
    font-size: 2.8rem;
    padding-top: 1.5rem;
  }
  .ramnavami-subheadline {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .ramnavami-content {
    padding: 0 3%;
  }
  
  .ramnavami-headline {
    font-size: 2.2rem;
    padding-top: 1rem;
  }
  
  .ramnavami-subheadline {
    font-size: 1.2rem;
    padding: 5px 20px;
  }
  
  .ramnavami-text-content {
    width: 98%; /* This remains as it was */
    padding: 0;
  }
  
  .ramnavami-text-content p {
    font-size: 1.1rem; /* This remains as it was */
  }

  .ramnavami-event-details p {
    font-size: 1.1rem; /* Added to revert to original size on mobile */
  }
}

@media (max-width: 480px) {
  .ramnavami-content-bg {
    padding-top: 2rem;
    margin-top: -1rem;
  }
  
  .ramnavami-headline {
    font-size: 1.8rem;
    padding-top: 0.5rem;
  }
  
  .ramnavami-subheadline {
    font-size: 1rem;
    padding: 4px 15px;
    margin: 1.5rem auto;
  }
  
  .ramnavami-text-content p {
    font-size: 1rem; /* This remains as it was */
  }

  .ramnavami-event-details p {
    font-size: 1rem; /* Added to revert to original size on mobile */
  }
}

@media (max-width: 360px) {
  .ramnavami-headline {
    font-size: 1.6rem;
  }
  
  .ramnavami-subheadline {
    font-size: 0.9rem;
  }
}