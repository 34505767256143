.donation-container {
  background: transparent;
  background-size: cover;
  padding: 5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .donation-box {
    background-color: #f9e4c9;
    padding: 15px;
    border-radius: 10px;
    width: 700px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(188, 53, 33, 1);
  }

  .form-header {
    font-size: 28px;
    /* color: rgba(188, 53, 33, 1); */
    color: #ff7300;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
    padding: 10px 0;
    border-bottom: 2px solid;
  }

  .donation-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  .option:last-child {
    /* grid-column: span 2; */
    width: 100%;
}
  .option {
    /* background-color: rgba(188, 53, 33, 1); */

    color: white;
    padding: 15px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.4;
    width: 330px;
    height: 70px;
  }

  /* .option:last-child {
    grid-column: span 2;
    width: 100%;
  } */

  label {
    display: block;
    color: #333;
    margin-bottom: 3px;
  }

  .required {
    color: rgba(188, 53, 33, 1);
    font-weight: bold;
    margin-left: 3px;
  }

  input[type="text"],
  input[type="email"],
  input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .form-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .form-col {
    display: flex;
    flex-direction: column;
  }

  .checkbox-section {
    margin-bottom: 20px;
    text-align: left;
  }

  .checkbox-label {
    color: #333;
    margin-left: 5px;
  }

  input[type="checkbox"] {
    accent-color: rgba(188, 53, 33, 1);
  }

  button.donate-button {
    background-color: rgba(188, 53, 33, 1);
    color: white;
    padding: 12px;
    border: 10px solid white;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
  }

  button.donate-button:hover {
    background-color: rgba(188, 53, 33, 1);
  }

  p {
    margin-top: 10px;
    color: #777;
    font-size: 12px;
    text-align: center;
  }

  @media (max-width: 768px) {
    .donation-container {
      padding: 5rem 0;
      background: url(./assets/mobile/mobilebg.webp);
      background-size: cover;
      background-position: center;
    }

    .donation-box {
      /* width: 100%; */
      border: 0;
      background-color: transparent;
    }

    .option {
      width: 100%;
      height: 100%;
      padding: 5px;
      border-radius: 4px;
      font-size: 8px;
      font-weight: bold;
      line-height: 1.4;
    }

    .form-header {
      font-size: 1.3rem;
      border: 0;
    }
    .form-group {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
      margin: 5px 0;
    }
    .form-group {
      width: 100%;
    }
    form label {
      width: 100%;
      align-items: start;
      color: #000;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .form-col > label {
      color: #000;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .form-col > input {
      margin: 0;
      padding: 2px 10px;
      box-shadow: 0 0 5px;
    }

    .form-col > input::placeholder {
      color: rgba(92, 92, 92, 0.8);
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 700;
    }
    .checkbox-section {
      margin-bottom: 10px;
    }
    .dona {
      margin: 0;
      padding: 2px 10px;
      box-shadow: 0 0 10px;
    }
    .dona::placeholder {
      color: rgba(92, 92, 92, 0.8);
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 700;
    }
    button.donate-button {
      padding: 8px 6px;
      border: 4px solid white;
      border-radius: 5px;
      font-size: 12px;
      width: 40%;
    }
  }
}

@media (max-width: 768px) {
  .donation-container {
    padding: 0;
    background-size: cover;
    background-position: center;
  }
}
