.philosophy-page {
  background: url("./assets/images/pageBg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 5rem;
}

.philosophy-img {
  padding: 30px 0 50px 0;
}

.philosophy {
  width: 90%;
}

@media (max-width: 600px) {
  .philosophy-img {
    padding: 20px 0 40px 0;
  }
}
