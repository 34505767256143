.quote {
  background-image: radial-gradient(#c61d21, #bb1b1f, #600e10);
  background-clip: text;
  color: transparent;
}

.sams {
  background-image: url(./assets/bg1.webp);
  background-position: center;
  background-size: cover;
  background-color: white;
}
