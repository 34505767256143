.design {
  background-image: radial-gradient(#c61d21, #b41a1e, #600e10);
  background-clip: text;
  color: transparent;
}

.festival {
  background-image: url(./assets/bg1.webp);
  background-size: cover;
}
