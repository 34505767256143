.main-section-wrapper {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}

.main-section-container {
  max-width: 90%;
  margin: 0 auto;
}

.main-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.text-section {
  font-family: "Open Sans-Regular", Helvetica;
  line-height: 1.5;
  letter-spacing: 0.08rem;
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.name-color {
  font-family: "Open Sans-Bold", Helvetica;
  font-weight: 700;
  color: #c61d21;
}

.image-section {
  order: -1;
  width: 100%;
  display: flex;
  justify-content: center;
}

.spiritual-image {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
  border-radius: 7px;
  box-shadow: 18px 20px 10px #e09c89, 0 6px 6px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 228, 214, 0.7);
}

@media (min-width: 768px) {
  .main-section-wrapper {
    padding: 40px;
  }

  .text-section {
    font-size: 26px;
    line-height: 1.6;
  }
}

@media (min-width: 1024px) {
  .main-section-wrapper {
    padding: 32px 60px;
  }

  .main-section {
    flex-direction: row;
    gap: 3rem;
  }

  .text-section {
    max-width: 72%;
    font-size: 30px;
    line-height: 2rem;
  }

  .image-section {
    flex: 1;
    order: 0;
    max-width: 33%;
  }

  .spiritual-image {
    width: auto;
  }
}

@media (min-width: 1440px) {
  .main-section-container {
    max-width: 90%;
  }
}
