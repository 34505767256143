.banner {
  background: url("../../assets/images/bannerBg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
  box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.3);
}

.left-bg {
  position: absolute;
  left: 0;
  top: 10;
  width: 20%;
  z-index: 1;
}

.right-bg {
  position: absolute;
  right: 0;
  top: 10;
  width: 20%;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
}

.headline {
  color: #c61d21;
  font-family: "Source Serif Pro";
  font-weight: 700;
  font-size: 3.4rem;
  word-spacing: 4px;
  letter-spacing: 2px;
}

.content span {
  font-size: 1.8rem;
  font-family: "Source Sans Pro";
  font-weight: 700;
  color: white;
  padding: 5px 20px;
  background-color: #c61d21;
  border: 3px solid white;
  border-top: 0;
  margin-bottom: 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.text {
  display: flex;
  justify-content: center;
}

.text-box {
  width: 80%;
  max-height: 900px;
  overflow: hidden;
  transition: max-height 1.5s ease-in-out;
}

.text-box.expanded {
  max-height: 2000px;
}

.text-box h5 {
  font-family: "Open Sans", sans-serif;
  text-align: justify;
  font-size: 1.5rem;
  word-spacing: 2px;
  letter-spacing: 1px;
  line-height: 1.3;
}

.readMore {
  padding-top: 20px;
}

@media (max-width: 600px) {
  .content {
    padding: 0 10px;
  }

  .content span {
    font-size: 1rem;
    padding: 5px 20px;
  }

  .headline {
    font-size: 1.6rem;
    font-family: "Source Sans Pro";
    font-weight: 800;
  }

  .text-box {
    width: 90%;
    max-height: 1200px;
  }

  .text-box.expanded {
    max-height: 2500px;
  }

  .text-box h5 {
    font-size: 1.2rem;
  }

  .show {
    display: block !important;
  }

  .hide {
    display: none !important;
  }

  .readMore {
    padding-top: 0px;
  }
}
