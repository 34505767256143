.card1 {
  background: url(./assets/bg1.webp);
}

.sunday {
  text-shadow: -4px -4px 0 #ffffff, 4px -4px 0 #ffffff, -4px 4px 0 #ffffff,
    4px 4px 0 #ffffff;
}

.bg2 {
  background: url(./assets/bg3.webp);
}

@media (max-width: 768px) {
  .bg2 {
    background: url(./assets/bg4.webp);
  }

  .card1 {
    background: url(./assets/bg1.webp);
  }
}

.donation {
  background: radial-gradient(50% 50% at 50% 50%, #c61d21 0%, #600e10 100%);
}
