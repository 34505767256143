.food-life-banner {
  width: 100%;
}

.food-life-page {
  background-image: url("./assets/images/Bg.webp");
  background-size: cover;
  padding: 5rem  5rem 2rem;
}

.food-life-head {
  font-family: "Open Sans", sans-serif;
  color: #de7d0d;
  font-weight: 700;
  font-size: 65px;
  text-align: center;
  padding-bottom: 20px;
}

.food-life-text {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5em;
  text-align: justify;
  padding-bottom: 10px;
}

.contribute-section {
  text-align: center;
  padding-top: 50px;
}

.food-life-span {
  font-size: 1.8rem;
  width: fit-content;
  font-weight: 600;
  word-spacing: 5px;
  color: white;
  padding: 5px 40px;
  background-color: #c61d21;
  border: 3px solid white;
  margin-bottom: 20px;
  border-radius: 10px;
  text-transform: uppercase;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
}

.secHead {
  margin-top: 25px;
  margin-bottom: 40px;
}

/* .first {
  grid-area: first;
}
.second {
  grid-area: second;
}
.third {
  grid-area: third;
}
.fourth {
  grid-area: fourth;
}
.fifth {
  grid-area: fifth;
}
.sixth {
  grid-area: sixth;
}
.seventh {
  grid-area: seventh;
} */

/* .contribution {
  background-color: rgba(227, 193, 149, 0.4);
  border: 1px solid #de7d0d;
  border-radius: 10px;
  padding: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "first second" "third fourth" "fifth sixth" "seventh .";
} */

/* .contribution .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff0dc;
  border: 1px solid #f08d49;
  padding: 20px 30px;
  border-radius: 10px;
  margin: 05px;
} */

/* .item h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 30px;
  margin: 0;
}

.item button {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  background-color: rgba(227, 193, 149, 0.2);
  padding: 05px 20px;
  border: 2px solid #bc3521;
  border-radius: 25px;
  color: #bc3521;
}
 */

/* form css */


.innerForm {
  @apply mt-8;
}

.sevaContainer {
  @apply flex flex-col gap-4;
}

.sevaContainer label {
  @apply bg-[#F9E7D0] p-3 rounded-xl flex items-center gap-3 font-bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.sevaContainer input {
  @apply rounded-xl text-[#909192];
}

/* .sevaContainer input:checked {
  @apply accent-blue-700;
} */

.sevaContainer .customAmount input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.sevaContainer .customAmount input[type="number"] {
  -moz-appearance: textfield;
}

.innerForm input {
  @apply rounded-xl;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.tandd label {
  @apply text-white text-[12px] flex items-center gap-2;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.dates .innerDate {
  @apply flex items-center gap-3 col-span-1 bg-[#EF6063] px-2 py-2 rounded-2xl;
}

.dates .innerDate .month {
  @apply bg-white px-3 py-2 xl:px-4 xl:py-3 rounded-xl text-lg;
}

.dates .innerDate .dateInfo p {
  @apply text-white text-[10px] xl:text-sm m-0;
}

.infoBox .innerInfoBox h3 {
  @apply text-2xl font-bold;
}

.infoBox .innerInfoBox p {
  @apply text-base text-left text-black;
}

.readMoreContent .infoBox .innerInfoBox h3 {
  @apply text-black;
}

.readMoreContent .infoBox .innerInfoBox p {
  @apply font-normal pt-2;
}


  .form-header {
    font-size: 28px;
    /* color: rgba(188, 53, 33, 1); */
    color: #ff7300;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
    padding: 10px 0;
    border-bottom: 2px solid;
  }

  .donation-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
    text-align: center;
  }

  .option {
    background-color: rgba(188, 53, 33, 1);

    color: white;
    padding: 15px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.4;
    width: 330px;
    height: 70px;
  }

  .option:last-child {
    /* grid-column: span 2; */
    width: 100%;
  }

  label {
    display: block;
    color: #333;
    margin-bottom: 3px;
  }

  .required {
    color: rgba(188, 53, 33, 1);
    font-weight: bold;
    margin-left: 3px;
  }

  input[type="text"],
  input[type="email"],
  input[type="date"] {
    width: 100% !important;
    padding: 10px !important;
    margin-bottom: 15px !important;
    /* border: 1px solid #ddd !important; */
    border-radius: 4px !important;
  }

  .form-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .form-col {
    display: flex;
    flex-direction: column;
  }

  .checkbox-section {
    margin-bottom: 20px;
    text-align: left;
  }

  .checkbox-label {
    color: #333;
    margin-left: 5px;
  }

  input[type="checkbox"] {
    accent-color: rgba(188, 53, 33, 1);
  }

  button.donate-button {
    background-color: rgba(188, 53, 33, 1);
    color: white;
    padding: 12px;
    border: 10px solid white;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
  }

  button.donate-button:hover {
    background-color: rgba(188, 53, 33, 1);
  }

  /* p {
    margin-top: 10px;
    color: #777;
    text-align: center;
  } */


@media (max-width: 678px) {
  .food-life-page {
    padding: 40px 15px;
  }

  .food-life-head {
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    padding-bottom: 20px;
  }

  .food-life-text {
    font-size: 1.2em;
    text-align: justify;
    padding-bottom: 10px;
  }

  .pt-4 {
    padding-top: 0 !important;
  }

  .secHead {
    margin-top: 10px;
  }

  .contribute-section {
    padding-top: 20px;
  }

  .food-life-span {
    font-size: 1.2rem;
    width: fit-content;
    word-spacing: 2px;
    padding: 5px 20px;
  }

  .contribution {
    padding: 20px;
    grid-template-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: "first" "third" "fifth" "seventh" "second" "fourth" "sixth";
  }

  .item {
    padding: 10px 20px;
  }

  .item h3 {
    font-size: 15px;
  }

  .item button {
    font-size: 12px;
    background-color: rgba(227, 193, 149, 0.2);
    padding: 05px 20px;
    border: 2px solid #bc3521;
    border-radius: 25px;
    color: #bc3521;
  }
}
  @media (max-width: 768px) {
    
    .donation-box {
      /* width: 100%; */
      border: 0;
      background-color: transparent;
    }

    .option {
      width: 100%;
      height: 100%;
      padding: 5px;
      border-radius: 4px;
      font-size: 8px;
      font-weight: bold;
      line-height: 1.4;
    }

    .form-header {
      font-size: 1.3rem;
      border: 0;
    }
    .form-group {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
      margin: 5px 0;
    }
    .form-group {
      width: 100%;
    }
    form label {
      width: 100%;
      align-items: start;
      color: #000;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .form-col > label {
      color: #000;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .form-col > input {
      margin: 0;
      padding: 2px 10px;
      box-shadow: 0 0 5px;
    }

    .form-col > input::placeholder {
      color: rgba(92, 92, 92, 0.8);
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 700;
    }
    .checkbox-section {
      margin-bottom: 10px;
    }
    .dona {
      margin: 0;
      padding: 2px 10px;
      box-shadow: 0 0 10px;
    }
    .dona::placeholder {
      color: rgba(92, 92, 92, 0.8);
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 700;
    }
    button.donate-button {
      padding: 8px 6px;
      border: 4px solid white;
      border-radius: 5px;
      font-size: 12px;
      width: 40%;
    }
  }

