.mission-page {
  background: url("./assets/images/pageBg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 4rem;
}

@media (max-width: 600px) {
  /* .philosophy-img {
    padding: 20px 0 40px 0;
  } */
}
