.heading-upper {
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.name {
  align-items: center;
  background-color: #c61d21;
  border: 2px solid #ffffff;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 15px;
  width: 200px;
}

@media (max-width: 767px) {
  .name {
    border-radius: 20px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Source Serif 4", serif;
    padding: 15px 0;
  }
}

@media (min-width: 768px) {
  .name {
    width: 254px;
    padding: 18px;
  }
}

.name .BHAKTI-SASHTRI {
  color: #ffffff;
  font-family: "Open Sans-Bold", Helvetica;
  font-size: 18px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  font-weight: 600;
}

@media (min-width: 768px) {
  .name .BHAKTI-SASHTRI {
    font-size: 25px;
    margin-top: -3.5px;
  }
}

.label {
  padding: 1rem;
  margin-top: 1rem;
}

.label .bhakti-shastri {
  color: #c61d21;
  font-family: "Open Sans-SemiBold", Helvetica;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

@media (min-width: 768px) and (max-width: 1169px) {
  .label {
    padding: 2rem;
  }

  .label .bhakti-shastri {
    font-size: 50px;
  }
}

@media (min-width: 1170px) {
  .label .bhakti-shastri {
    font-size: 53px;
  }
}

.label .span {
  font-family: "Open Sans-Bold", Helvetica;
  font-weight: 700;
  display: block;
  margin-top: 0.5rem;
}

.full-text {
  display: block;
}

/* Hidden by default for the mobile-specific text */
.mobile-text {
  display: none;
}

/* For mobile view, hide the full text and show the mobile-specific text */
@media (max-width: 767px) {
  .full-text {
    display: none;
  }

  .mobile-text {
    display: block;
  }
}
